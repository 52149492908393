<template>
  <el-card class="v_user_cas_out_list">
    <div class="g-flex"  slot="header">
      <span>代理提现列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="exportClick">导出列表</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="状态">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="撤单" value="0"></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="打款中" value="3"></el-option>
          <el-option label="到账成功" value="2"></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="提现类型：">
        <el-select v-model="query.type" placeholder="请选择" @change="getList()" >
          <el-option v-for="item in options" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="提现银行">
        <el-select v-model="query.bank_type" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="银行卡" value="1"></el-option>
          <el-option label="支付宝" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="手机号" value="mobile"></el-option>
          <el-option label="提现编号" value="id"></el-option>
          <el-option label="用户ID" value="user_id"></el-option>
        </el-select>
        <el-row>
          <el-col :span="10">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="3" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="3" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
           <el-button  size="mini" type="warning" @click="apiCashMuchSuccessHandel(confirmIds)">批量确认到账</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 stripe --> 
    <el-table :row-class-name="tableRowClassName" @selection-change="handleSelectionChange"  size="small" v-loading="table.loading" :data="table.rows" border>
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column label="用户手机号" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="提现编号" width="120" :show-overflow-tooltip="true"/>
      <!-- <el-table-column prop="user_id" label="用户ID" width="120" :show-overflow-tooltip="true"/> -->
      <el-table-column label="提现类型" width="100" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14 g-red" v-show="scope.row.type === 1">{{scope.row.title}}</span>
          <span class="g-fsize14 g-green" v-show="scope.row.type === 2">{{scope.row.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现人姓名" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现银行" width="120" :show-overflow-tooltip="true">
        <template  slot-scope="scope">
          <span class="g-fsize14 g-red" v-show="scope.row.bank_type === 1">{{scope.row.bank_name}}</span>
          <span class="g-fsize14 g-green" v-show="scope.row.bank_type === 2">{{scope.row.bank_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现卡号" width="220" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.card_number}}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请提现金额" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.pay_money}}</span>
        </template>
      </el-table-column>

      <el-table-column label="实际提现金额" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="g-fsize14">{{scope.row.real_money}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现时间"  width="170" :show-overflow-tooltip="true" fixed="right">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" width="170" :show-overflow-tooltip="true" fixed="right">
        <template slot-scope="scope">
          <span class="g-fsize14">{{ formatDate(scope.row.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" fixed="right" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="g-red">撤单</span>
            <span v-else-if="scope.row.status==1" class="g-purple">待审核</span>
            <span v-else-if="scope.row.status==2" class="g-green">到账成功</span>
            <span v-else-if="scope.row.status==3" class="g-blue">打款中</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="220" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 1" size="mini" type="primary" @click="setForm(scope.row, 1)">审核</el-button>
          <el-button v-if="scope.row.status == 3" size="mini" type="success" @click="apiCashSuccessHandel(scope.row.id)">确认到账</el-button>
          <el-button v-if="scope.row.status == 1" size="mini" type="warning" @click="apiCashSuccessHandel(scope.row.id)">一键到账</el-button>
          <el-button size="mini" type="danger" @click="setForm(scope.row, 2)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

    <!-- 提现弹窗 -->
    <dai-li-cash-out-list-pop ref="DaiLiCashOutListPop" @emitPass="getList(false)"/>

    <!-- 导出弹窗 -->
    <dai-li-cash-out-list-export-pop ref="DaiLiCashOutListExportPop"  @emitSaveSuccess="getList()"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apigetAgentCashList, apiCashSuccess, apiBatchCashSuccess } from '@/utils/api.js'
  import DaiLiCashOutListPop from './DaiLiCashOutListPop.vue'
  import DaiLiCashOutListExportPop from './DaiLiCashOutListExportPop.vue'
  import { mixins } from '@/utils/mixins.js'
  export default {
    name: 'userCashOutList',
    mixins: [mixins],
    components: { DaiLiCashOutListPop, DaiLiCashOutListExportPop },
    data() {
      return {
        
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { search_key: 'mobile',search_val: '', type: 'ALL', bank_type: 'ALL', page:1, limit: 15, status: 'ALL' },

        table:{ loading:false, total:0, rows:[] },
        
        confirmIds: '',

        formData: {
          ent_id: '',
          ip: '',
          expir_time: 0,
        },

        // options: [
        //   { title: '全部', value: 'ALL' },
        //   { title: '本金提现', value: 1 },
        //   { title: '佣金提现', value: 2 },
        // ]
      }
    },
    created() {
      this.getList()
    },
    methods: {
      formatDate,
      
      // 点击导出
      exportClick() {
        this.$refs.DaiLiCashOutListExportPop.onShow()
      },

      // 多选
      handleSelectionChange(arr) {
        console.log(arr)
        this.confirmIds = arr.map(item => { return item.id }).join(',')
      },
      // 批量确认到账
      async apiCashMuchSuccessHandel(ids) {
        if(!ids) return this.$message.error('请选择确认到账的记录')
        this.$confirm('此操作将会变更为到账成功状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiBatchCashSuccess({ ids: ids }).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        }).catch(() => {});
      },

      //提现确认到账
      async apiCashSuccessHandel(id) {
        if(!id) return this.$message.error('请选择确认到账的记录')
        this.$confirm('此操作将会变更为到账成功状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiCashSuccess({ id }).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        }).catch(() => {});
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apigetAgentCashList(this.query).catch(() =>  this.table.loading = false)
        this.table.loading = false
        if(!success) return
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: '' }, status) {
        this.formData = JSON.parse(JSON.stringify(formData))
        this.$refs.DaiLiCashOutListPop.onShow(this.formData, status)
      },
      
      // 点击重置
      resetData() {
        this.query = { search_key: 'mobile',search_val: '', type: 'ALL', bank_type: 'ALL', page:1, limit: 15, status: 'ALL' },
        this.getList()
      }
      
    }
  }
</script>

<style lang="scss">
</style>